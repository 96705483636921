import { h } from 'vue'
import { NButton, NEllipsis, NText } from 'naive-ui'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { navigateTo } from '#app'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/store/auth'

export default function () {
  const localePath = useLocalePath()
  const localeRoute = useLocaleRoute()
  const store = useAuthStore()
  const { unsetToken } = store
  const { isAdmin } = storeToRefs(store)

  const router = useRouter()

  const { t } = useI18n()

  const getRoute = route => localePath({ name: route })
  const getRouteName = route => localeRoute({ name: route })?.name ?? route

  const renderClick = ({ route }) => {
    return typeof route !== 'string'
      ? route
      : () => {
          navigateTo(getRoute(route))
        }
  }
  const renderIcon = (icon: string[], route?: string| Function) => {
    if (!route) {
      return () => h(FontAwesomeIcon, {
        icon
      })
    }

    return () => h(FontAwesomeIcon, {
      icon,
      onClick: renderClick({ route })
    })
  }

  const renderLink = (route: string, text: string) => {
    return () => h(NButton, {
      text: true,
      block: true,
      strong: true,
      style: 'min-height:var(--n-item-height);height:100%; justify-content:start;align-items:center;',
      onClick: renderClick({ route })
    }, {
      default: () => h(NEllipsis, null, {
        default: () => t(`menu.${text}`)
      })
    })
  }

  const renderGroupLabel = ({ text }) => {
    return h(NButton, {
      text: true,
      block: true,
      strong: true,
      style: 'min-height:var(--n-item-height);height:100%; justify-content:start;align-items:center;'
    }, {
      default: () => t(`menu.${text}`)
    })
  }
  const generateLabelIcon = (route: string, text: string, icon: string[]) => {
    return {
      label: renderLink(route, text),
      icon: renderIcon(icon, route),
      onClick: renderClick({ route })
    }
  }

  const logout = () => {
    sessionStorage.removeItem('token')
    unsetToken()
    setTimeout(() => {
      router.push(localePath({ name: 'auth-login' }))
    }, 200)
  }
  const options = computed(() => {
    return [
      {
        type: 'divider',
        key: 'divider-1'
      },
      {
        key: getRouteName(!isAdmin.value ? 'index' : 'admin'),
        ...generateLabelIcon(!isAdmin.value ? 'index' : 'admin', 'home', ['fas', 'house'])
      },
      {
        type: 'divider',
        key: 'divider'
      },
      {
        key: getRouteName('files'),
        ...generateLabelIcon('files', 'files', ['fas', 'file']),
        show: !isAdmin.value
      },
      {
        key: getRouteName('admin-users'),
        ...generateLabelIcon('admin-users', 'users', ['fas', 'users']),
        show: isAdmin.value
      },
      {
        label: () => renderGroupLabel({ text: 'orders.base' }),
        icon: renderIcon(['fas', 'cart-shopping']),
        key: 'order',
        show: !isAdmin.value,
        children: [
          {
            key: getRouteName('orders-new'),
            ...generateLabelIcon('orders-new', 'orders.new', ['fas', 'cart-plus'])
          },
          {
            key: getRouteName('orders'),
            ...generateLabelIcon('orders', 'orders.mine', ['fas', 'layer-group'])
          }
        ]
      },
      {
        key: getRouteName('admin-orders'),
        ...generateLabelIcon('admin-orders', 'orders.all', ['fas', 'cart-shopping']),
        show: isAdmin.value
      },
      {
        label: () => renderGroupLabel({ text: 'subscriptions.base' }),
        icon: renderIcon(['fas', 'list']),
        key: 'subscriptions',
        show: !isAdmin.value,
        children: [
          {
            key: getRouteName('subscriptions-new'),
            ...generateLabelIcon('subscriptions-new', 'subscriptions.new', ['fas', 'list-ol']),
            show: !isAdmin.value
          },
          {
            key: getRouteName('subscriptions'),
            ...generateLabelIcon('subscriptions', 'subscriptions.mine', ['fas', 'list-check']),
            show: !isAdmin.value
          }
        ]
      },
      {
        key: getRouteName('admin-subscriptions'),
        ...generateLabelIcon('admin-subscriptions', 'subscriptions.all', ['fas', 'list-check']),
        show: isAdmin.value
      },
      {
        label: () => renderGroupLabel({ text: 'configurations' }),
        icon: renderIcon(['fas', 'gears']),
        key: 'configurations',
        show: isAdmin.value,
        children: [
          {
            key: getRouteName('admin-config-subscriptions'),
            ...generateLabelIcon('admin-config-subscriptions', 'config.subscriptions', ['fas', 'money-check-dollar']),
            show: isAdmin.value
          },
          {
            key: getRouteName('admin-config-print-options'),
            ...generateLabelIcon('admin-config-print-options', 'config.print-options', ['fas', 'object-group']),
            show: isAdmin.value
          },
          {
            key: getRouteName('admin-config-calendar'),
            ...generateLabelIcon('admin-config-calendar', 'calendar', ['fas', 'calendar-days']),
            show: isAdmin.value
          },
          {
            key: getRouteName('admin-config-invoice'),
            ...generateLabelIcon('admin-config-invoice', 'invoice', ['fas', 'file-invoice']),
            show: isAdmin.value
          },
          {
            key: getRouteName('admin-config-mail'),
            ...generateLabelIcon('admin-config-mail', 'mail', ['fas', 'envelopes-bulk']),
            show: isAdmin.value
          }
        ]
      },
      {
        type: 'divider',
        key: 'divider-2'
      },
      {
        key: getRouteName('account'),
        ...generateLabelIcon('account', 'account', ['fas', 'user-tie'])
      },
      {
        label: () => h(NButton, {
          text: true,
          block: true,
          style: 'height:var(--n-item-height); justify-content:start;',
          onClick: logout
        }, { default: () => t('menu.logout') }),
        key: 'logout',
        icon: renderIcon(['fas', 'right-from-bracket'], logout)
      }
    ]
  })

  return {
    options
  }
}
