<script setup lang="ts">

import { NListItem, NTag, NThing } from 'naive-ui'

type Props = {
  type: string
  searchable: any,
  selected: boolean,
}
const { t } = useI18n()
const emit = defineEmits<{(e: 'onHover', hover:boolean): void }>()
const props = withDefaults(defineProps<Props>(), {
  selected: false
})

const searchable = toRef(props, 'searchable')
const type = toRef(props, 'type')
const refItem = ref(null)
const renderUser = (option) => {
  return `${option.lastname}`
}
const description = computed(() => {
  const user = type.value === 'users' ? searchable.value : searchable.value.user
  return `${user.email}`
})
const title = computed(() => {
  if (type.value === 'users') {
    return `${searchable.value.firstname} ${searchable.value.lastname}`
  }
  if (type.value === 'orders') {
    return `${searchable.value.number}`
  }
  if (type.value === 'subscriptions') {
    return `${searchable.value.name}`
  }
})
const renderOrder = (option) => {
  return `${option.number}`
}
const renderLabel = () => {
  if (props.type === 'users') {
    return renderUser({ ...props.searchable })
  }
  if (props.type === 'orders') {
    return renderOrder({ ...props.searchable })
  }
}
</script>

<template>
  <NListItem
    ref="refItem"
    :class="{ hover: selected}"
    @mouseenter="emit('onHover', true)"
    @mouseleave="emit('onHover', false)"
  >
    <NThing :title="title" :description="description">
      <template #header-extra>
        <NTag :type="props.type === 'users' ? 'primary' : 'info'">
          {{ t(`search_type.${props.type}`) }}
        </NTag>
      </template>
    </NThing>
  </NListItem>
</template>

<style scoped>
.n-list-item.hover {
  background-color: var(--n-merged-color-hover);
}
</style>
