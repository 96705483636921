<script setup lang="ts">
import { ComputedRef, onBeforeUnmount, onMounted, ref } from 'vue'
import { useNuxtApp } from 'nuxt/app'
import {
  NModal,
  NCard,
  NInput,
  NList, NScrollbar, InputProps, CardProps, useThemeVars, NLayout
} from 'naive-ui'
import axios from 'axios'
import { search as apiSearch } from '~/utils/apiAdmin'
import SearchItem from '~/components/commons/GlobalSearch/searchItem.vue'

const theme = useThemeVars()
const localePath = useLocalePath()
const { $breakpoint } = useNuxtApp()
const { t } = useI18n()
const { isSmall } = $breakpoint
const displaySearch = ref(false)
const isSearching = ref(false)
const searchInput = ref(null)
const fakeInput = ref(null)
const timer = ref()

type EntryType = {
  type: string,
  searchable: any
}
const entries = ref<EntryType[]>([])
const searchTerm = ref<string|null>()
const scrollBarRef = ref()
const itemHeight = 78
const selected = ref<{key:string|null, index: number|null}>({
  key: null,
  index: null
})

const listRef = ref(null)
const abortController = ref(null)

const scrollList = (top: number) => {
  scrollBarRef.value.scrollTo({
    top,
    scrollBehavior: 'smooth'
  })
}
const navigate = (key) => {
  if (key !== null && entries.value[key]) {
    const { type, searchable } = entries.value[key]

    const route = localePath({ name: `admin-${type}-id`, params: { id: searchable.id } })
    closeSearch()
    return navigateTo(route)
  }
}
const shortCutHandler = (event) => {
  if (event.key === 'k' && event.metaKey === true) {
    event.preventDefault()

    if (displaySearch.value === true) {
      closeSearch()
      return
    }
    activateSearch()
  }

  if (event.key === 'Enter') {
    event.preventDefault()
    return navigate(selected.value?.index)
  }

  if (entries.value.length > 0 && event.key === 'ArrowDown') {
    event.preventDefault()
    const max = entries.value.length - 1
    const current = selected.value?.index !== null
      ? selected.value.index
      : -1
    const next = current + 1 > max ? 0 : current + 1
    const top = next >= 3 ? (itemHeight * next) - (itemHeight * 2) : 0
    selectItem(next)
    scrollList(top)
  }

  if (entries.value.length > 0 && event.key === 'ArrowUp') {
    event.preventDefault()
    const max = entries.value.length
    const current = selected.value?.index !== null
      ? selected.value.index
      : max
    const next = current - 1 < 0 ? max - 1 : current - 1

    selectItem(next)
    scrollList((itemHeight * next) - (itemHeight * 2))
  }
}

const selectItem = (index: number|null) => {
  if (index === null) {
    selected.value.index = null
    selected.value.key = null
    return
  }
  const item = entries.value[index]

  selected.value.index = index
  selected.value.key = `${item.type}-${item.searchable.id}`
}

type themeInputOverride = NonNullable<InputProps['themeOverrides']>

const inputTheme: ComputedRef<themeInputOverride> = computed(() => {
  return {
    heightMedium: '60px',
    iconSize: '50px',
    fontSizeMedium: '40px'
  }
})

type themeCardOverride = NonNullable<CardProps['themeOverrides']>

const cardTheme: ComputedRef<themeCardOverride> = computed(() => {
  return {
    borderColor: theme.value.primaryColor,
    paddingSmall: '5px',
    paddingMedium: '5px',
    paddingLarge: '5px',
    paddingHuge: '5px'
  }
})

const activateSearch = () => {
  fakeInput.value && fakeInput.value.blur()
  displaySearch.value = true
  selectItem(null)
  entries.value = []
  searchTerm.value = null
}
// const options = computed(() => entries.value.map((e:UserModel) => `${e.firstname} ${e.lastname} ${e.email}`))
const onClick = (key) => {
  return navigate(key)

}
const search = async (val: string|null) => {
  clearTimeout(timer.value)
  entries.value = []
  timer.value = setTimeout(async () => {
    if (!val || val.length < 3) {
      isSearching.value = false
      return
    }

    try {
      isSearching.value = true
      if (abortController.value) {
        abortController.value.abort()
        abortController.value = null
      }

      abortController.value = new AbortController()
      const data = await apiSearch({ search: val }, {
        signal: abortController.value.signal
      })
      entries.value = data
    } catch (thrown) {
      if (axios.isCancel(thrown)) {
        console.error('Request canceled', thrown.message)
      } else {
        console.error(thrown)
      }
    } finally {
      isSearching.value = false
    }
  }, 500)
}
watch(searchInput, v => v && searchInput.value?.focus())
watch(searchTerm, v => search(v))

defineExpose({
  activateSearch
})

onMounted(() => {
  document.addEventListener('keydown', shortCutHandler)
})

onBeforeUnmount(() => {
  document.removeEventListener('keydown', shortCutHandler)
})

const onEsc = () => {
  if (selected.value.index) {
    selectItem(null)
  } else {
    closeSearch()
  }
}
const closeSearch = () => {
  displaySearch.value = false
  selectItem(null)
  entries.value = []
  searchTerm.value = null
}
</script>

<template>
  <NLayout>
    <NInput
      ref="fakeInput"
      size="large"
      :placeholder="t('Search')"
      @click="activateSearch"
    />
    <NModal
      v-model:show="displaySearch"
      role="dialog"
      :style="{
        width: isSmall ? '100%' : '60%'
      }"
      transfrom-origin="center"
      :on-close="closeSearch"
      :on-mask-click="closeSearch"
      :on-esc="closeSearch"
    >
      <NCard :theme-overrides="cardTheme">
        <NInput
          ref="searchInput"
          v-model:value="searchTerm"
          :input-props="{
            autocomplete: 'disabled'
          }"
          :loading="isSearching"
          placeholder="Search"
          :theme-overrides="inputTheme"
          @focus="selectItem(null)"
          @keydown.esc.stop="onEsc"
        />
        <NList v-if="entries.length > 0" ref="listRef" clickable bordered class="results-wrapper mt-2">
          <NScrollbar
            ref="scrollBarRef"
            trigger="none"
            :style="`max-height: ${5 * itemHeight}px`"
          >
            <SearchItem
              v-for="(entry, key) in entries"
              :key="key"
              v-bind="entry"
              :selected="selected.index === key"
              @on-hover="(bool) => bool ? selectItem(key) : selectItem(null)"
              @click="() => onClick(key)"
            />
          </NScrollbar>
        </NList>
      </NCard>
    </NModal>
  </NLayout>
</template>

<style scoped>

.n-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
.results-wrapper  {
  position: absolute;
  flex: 1;
  top: 70px;
  left: 0;
  width: calc(100% - 2px);
}
</style>
