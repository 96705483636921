<template>
  <NLayout has-sider class="full">
    <NLayoutSider
      bordered
      show-trigger
      collapse-mode="width"
      :collapsed-width="64"
      :width="240"
      :native-scrollbar="false"
      :collapsed="collapsed"
      @collapse="collapsed = true"
      @expand="collapsed = false"
    >
      <NSpace
        align="center"
        justify="center"
        :style="{
          paddingRight: !collapsed ? '18px' : null,
          height:'80px',
          overflow: 'hidden',
          flex:1,
        }"
      >
        <!--        <Transition name="fade" mode="out-in" :style="{ height: '100%'}">-->
        <!--          <Icon v-if="collapsed" :style="{ height: '60px', width: '60px' }" />-->
        <!--          <Logo v-else :style="{ width: '100%'}" />-->
        <!--        </Transition>-->
        <Transition name="fade" mode="out-in" :style="{ height: '100%'}">
          <IconIdfx v-if="collapsed" :style="{ height: '80px', width: '50px' }" />
          <LogoIdfx v-else :style="{ height: '80px', width: '100%'}" />
        </Transition>
      </NSpace>
      <!--      <NSpace justify="center" align="center" :vertical="collapsed">-->
      <!--        <nuxt-link :to="switchLocalePath('en')">-->
      <!--          <UK :style="{width:'20px'}" />-->
      <!--        </nuxt-link>-->
      <!--        <nuxt-link :to="switchLocalePath('fr')">-->
      <!--          <FR :style="{width:'20px'}" />-->
      <!--        </nuxt-link>-->
      <!--      </NSpace>-->
      <NMenu
        :value="selectedMenu"
        :default-value="route.name"
        :root-indent="16"
        :indent="32"
        :collapsed-width="64"
        :collapsed-icon-size="22"
        :options="menuOptions"
        :collapsed="collapsed"
        @update:value="onSelectMenu"
      />
    </NLayoutSider>
    <NLayout class="full">
      <NLayoutHeader style="padding: 10px" :inverted="inverted" bordered>
        <NSpace justify="space-between" align="center">
          <NButton text native-focus-behavior @click="collapsed = !collapsed">
            <template #icon>
              <FontAwesomeIcon :icon="['fas', 'bars']" size="xl" />
            </template>
          </NButton>
          <NSpace align="center">
            <NButton native-focus-behavior icon-placement="left" size="medium" @click="$router.back()">
              {{ t('Back') }}
              <template #icon>
                <FontAwesomeIcon :icon="['fas', 'angles-left']" />
              </template>
            </NButton>
            <GlobalSearch v-if="isAdmin" />
          </NSpace>
        </NSpace>
      </NLayoutHeader>
      <NLayout
        :content-style="{
          padding: isBelowMd ? '5px' : '24px'
        }"
      >
        <slot />
      </NLayout>
    </NLayout>
  </NLayout>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import {
  NMenu,
  NLayoutSider,
  NLayout,
  NLayoutHeader,
  NSpace,
  NButton
} from 'naive-ui'
import { useNuxtApp } from '#app'
import { storeToRefs } from 'pinia'
import { useAuthStore } from '~/store/auth'
import UK from '~/assets/flag-gb.svg?component'
import FR from '~/assets/flag-fr.svg?component'
import LogoIdfx from '~/assets/logo3idfx.svg?component'
import IconIdfx from '~/assets/3idfx_icon.svg?component'
// import Logo from '~/assets/logo.svg?component'
// import Icon from '~/assets/icon.svg?component'
import { me } from '~/utils/api'
import { GlobalSearch } from '~/components'
import { useAppStore } from '~/store/app'
const route = useRoute()
definePageMeta({
  key: route => route.fullPath
})
const store = useAuthStore()
const { user, isAdmin } = storeToRefs(store)

const appStore = useAppStore()
const { collapsed } = storeToRefs(appStore)

const { setUser } = store
const { options: menuOptions } = useMenu()
const { $pusher } = useNuxtApp()
const { success } = useNotification()
const { t } = useI18n()

const { $breakpoint } = useNuxtApp()
const { listen, isBelowMd } = $breakpoint

const inverted = ref(false)
appStore.setCollapsed(isBelowMd.value)

const listener = listen('md', ({ matches }) => {
  if (!matches && !collapsed.value) {
    appStore.setCollapsed(true)
  }

  if (matches && collapsed.value) {
    appStore.setCollapsed(false)
  }
})
onUnmounted(() => {
  listener.off()
})
const selectedMenu = ref(route.name)
const onSelectMenu = key => selectedMenu.value = key
watch(() => route.name, value => selectedMenu.value = value)

onBeforeRouteUpdate((to, from, next) => {
  console.log(to, from, next)
})
watch(user, (user) => {
  if (!user) {
    return
  }

  const channelPrivateUser = $pusher.subscribe(`private-App.Models.User.${user.id}`)
  channelPrivateUser.unbind_all()
  channelPrivateUser.bind('file.analyzed', (event) => {
    const { file } = event
    success({
      title: t('notification.fileAnalyzed.title'),
      description: t('notification.fileAnalyzed.description', { file: file.name }),
      content: t('notification.fileAnalyzed.content', {
        blackCount: file.black_pages.length,
        colorCount: file.color_pages.length
      })
    })
  })
})

onMounted(async () => {
  const user = await me()
  appStore.setCollapsed(isBelowMd.value)
  setUser(user)
})
</script>

<style scoped lang="scss">

</style>
